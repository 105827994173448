import { Divider, Table } from "antd";
import React from "react";

const columns = [
  {
    title: "",
    dataIndex: "day",
    key: "day",
  },
  {
    title: "",
    dataIndex: "morning",
    key: "morning",
  },
  {
    title: "",
    dataIndex: "afternoon",
    key: "afternoon",
  },
];

const data = [
  {
    key: "1",
    day: "Po",
    morning: "7:30 - 11:30",
    afternoon: "12:00 - 15:00",
  },
  {
    key: "2",
    day: "Út",
    morning: "10:00 - 13:00",
    afternoon: "13:30 - 17:30",
  },
  {
    key: "3",
    day: "St",
    morning: "7:30 - 11:30",
    afternoon: "12:00 - 15:00",
  },
  {
    key: "4",
    day: "Čt",
    morning: "7:30 - 11:30",
    afternoon: "12:00 - 15:00",
  },
  {
    key: "5",
    day: "Pá",
    morning: "7:30 - 11:30",
    afternoon: "12:00 - 15:00",
  },
];

class Schedule extends React.Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Divider>Ordinační hodiny</Divider>
        <Table
          columns={columns}
          dataSource={data}
          size="small"
          pagination={false}
          showHeader={false}
          style={{ width: "260px", margin: "auto" }}
        />
      </React.Fragment>
    );
  }
}

export default Schedule;
