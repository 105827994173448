import { Card, Divider, Row, Col, Grid } from "antd";
import React, { useEffect, useState } from "react";

const filePath = `${window.location.origin}/Team/`;
const teamData = [
  [
    { name: "MDDr. Zdeněk Klimeš", title: "zubní lékař", foto: "zdenek" },
    {
      name: "Jindřiška Hrdličková",
      title: "zdravotní sestra",
      foto: "jindriska",
    },
  ],
  [
    { name: "MDDr. Leona Držmíšková", title: "zubní lékařka", foto: "leona" },
    { name: "Silvie Pechová", title: "zubní instrumentářka", foto: "silvie" },
  ],
  [
    { name: "Monika Kulíšková", title: "dentální hygienistka", foto: "monika" },
    {
      name: "Růženka Záškodová",
      title: "zubní laborantka / dentální hygienistka",
      foto: "ruzenka",
    },
  ],
];

const { useBreakpoint } = Grid;

const personCard = ({ name, title, foto }, index, breakpoints) => (
  <Col xs={24} sm={24} md={12} style={{ padding: "5px 10px" }}>
    <Card
      style={
        !breakpoints.md
          ? { margin: "auto", minWidth: "200px", maxWidth: "300px" }
          : {
              position: "relative",
              float: index === 0 ? "right" : "left",
              minWidth: "200px",
              maxWidth: "300px",
            }
      }
      cover={<img alt="person" src={`${filePath}/${foto}.jpg`} />}
    >
      <Card.Meta title={name} description={`${title}`} />
    </Card>
  </Col>
);

const Team = () => {
  const breakpoints = useBreakpoint();
  const [team, setTeam] = useState(teamData);

  useEffect(() => {
    console.log(breakpoints.md);
    if (breakpoints.md === false) {
      const teamDoctors = [];
      const teamSisters = [];

      teamData.forEach((double) => {
        teamDoctors.push(double[0]);
        teamSisters.push(double[1]);
      });

      const teamTogether = teamDoctors.concat(teamSisters);
      const teamDoubles = [];

      for (let i = 0; i < teamTogether.length - 1; i += 2) {
        teamDoubles.push([teamTogether[i], teamTogether[i + 1]]);
      }
      setTeam(teamDoubles);
    } else {
      setTeam(teamData);
    }
  }, [breakpoints.md]);

  return (
    <React.Fragment>
      <Divider>Tým</Divider>

      {team.map((data) => (
        <Row justify="center">
          {personCard(data[0], 0, breakpoints)}
          {personCard(data[1], 1, breakpoints)}
        </Row>
      ))}
    </React.Fragment>
  );
};

export default Team;
