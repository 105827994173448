import { Card, Divider, Row, Col } from "antd";
import React from "react";
const filePath = `${window.location.origin}/SurgeryImages/`;

class Surgery extends React.Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Divider>Zubní ordinace</Divider>
        <Row>
          <Col xs={24} sm={24} lg={12} style={{ padding: "5px" }}>
            <Card
              style={{
                margin: "auto",
                maxWidth: "500px",
              }}
              cover={<img alt="ordinace" src={`${filePath}/l.jpg`} />}
            ></Card>
          </Col>
          <Col xs={24} sm={24} lg={12} style={{ padding: "5px" }}>
            <Card
              style={{
                margin: "auto",
                maxWidth: "500px",
              }}
              cover={<img alt="ordinace" src={`${filePath}/r.jpg`} />}
            ></Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Surgery;
