import React from "react";
import { Element } from "react-scroll";
import "./HomePage.css";
import Contact from "./Components/Contact";
import Equipment from "./Components/Equipment";
import Home from "./Components/Home";
import News from "./Components/News";
// import OnlineBooking from "./Components/OnlineBooking";
import PriceList from "./Components/PriceList";
import Schedule from "./Components/Schedule";
import Team from "./Components/Team";
import Surgery from "./Components/Surgery";
import Toothbrushing from "./Components/Toothbrushing";

class HomePage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Element name="home">
          <Home />
        </Element>
        <Element name="news">
          <News />
        </Element>
        <Element name="schedule">
          <Schedule />
        </Element>
        <Element name="team">
          <Team />
        </Element>
        <Element name="price">
          <PriceList />
        </Element>
        <Element name="equipment">
          <Equipment />
        </Element>
        {/* <Element name="booking">
          <OnlineBooking />
        </Element> */}
        <Element name="surgery">
          <Surgery />
        </Element>
        <Element name="toothbrushing">
          <Toothbrushing />
        </Element>
        <Element name="contact">
          <Contact />
        </Element>
      </React.Fragment>
    );
  }
}

export default HomePage;
