import { Layout } from "antd";
import React from "react";
const { Header } = Layout;

class MyHeader extends React.Component {
  state = {};
  render() {
    const mobileStyle = this.props.mobile
      ? {
          position: "fixed",
          zIndex: 1,
          width: "100%",
          margin: "0",
          paddingTop: "10px",
          height: 64,
        }
      : {};
    return (
      <React.Fragment>
        {this.props.mobile ? <div style={{ height: 64 }}></div> : []}
        <Header
          style={{
            background: "#fff",
            paddingTop: 0,
            margin: "16px 16px 0 16px",
            height: 120,
            ...mobileStyle,
          }}
        >
          <div style={{ margin: "auto", textAlign: "center" }}>
            {this.props.mobile ? (
              <React.Fragment>
                <p
                  style={{
                    margin: 0,
                    paddingTop: 2,
                    lineHeight: "24px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  STOMATOLOGIE
                </p>
                <p
                  style={{
                    lineHeight: "22px",
                    fontSize: "13px",
                    marginLeft: "110px",
                  }}
                >
                  NA SLUNCI
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h1
                  style={{
                    fontSize: "325%",
                    margin: 0,
                    paddingTop: 10,
                    fontWeight: "bold",
                  }}
                >
                  STOMATOLOGIE
                </h1>
                <h2 style={{ margin: "0 0 0 284px", lineHeight: "22px" }}>
                  NA SLUNCI
                </h2>
              </React.Fragment>
            )}
          </div>
        </Header>
      </React.Fragment>
    );
  }
}

export default MyHeader;
